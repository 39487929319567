.errRec h1 {
    color: #c70101;
}

h1 {
    text-align: center;
    color: #7a6fbe;
    position: relative;
    z-index: 1;
    margin-top: 50px;
}

.animate .zoomChild {
    /*scale: 2;*/
    /*margin-top: 100px;*/
    transition: all 2s ease;
}

.zoomChild {
    scale: 1;
    position: absolute;
    margin-top: 0px;
    width: 80px;
    height: 80px;
    transition: all .4s ease;

}
.svgContainer.animate {
    height: 100px;
    margin-bottom: -10px;
}
.svgContainer {
    position: relative;
    display: block;

    width: 80px;
    height: 80px;
    cursor: pointer;
    margin: 0 auto;
    margin-top: 20px;
    transition: all 1s ease, height 0s ease, margin-bottom 0s ease;


}

.svgContainer.animate .delay1 {
    animation: wavess 2.5s ease;
    animation-delay: 0.1s;
}
.svgContainer.animate .delay2 {
    animation: wavess 2.5s ease 0.7s infinite;
}
.svgContainer.animate .delay3 {
    animation: wavess 2.5s ease 1.3s forwards infinite;
}
.svgContainer.animate .delay4 {
    animation: wavess 2.5s ease 1.9s forwards infinite;
}

.animate .svg-box {
    border: none;
    box-shadow: none;
}
.svg-box {
    position: relative;
    z-index: 10;
    box-shadow: 0px 0px 8px 2px #7a6fbe;
}
.counting {
    opacity: 0;
    display: none;
}
.animate .counting {
    font-weight: bold;
    text-align: center;
    width: 100%;
    position: absolute;
    margin-top: 8px;
    font-size: 20PX;
    display: block;
    animation: fade 1s ease 1s forwards;
}
.initMicCont {
    width: 100%;
    position: absolute;
    top: 75px;
    left: 0;
    font-size: 24px;
    text-align: center;
    z-index: 10001;
    margin: 0 auto;
    font-weight: bold;
}
.fadeDelay {
    z-index: 1000000;
    opacity: 0;
    animation: fadeDelay 1s ease forwards;
    animation-delay: 1s
}
@keyframes fadeDelay {
    from {
        opacity: 0;
        cursor: default;
    }
    100% {
        opacity: 1
    }
}
/*.initMic::after {*/
/*    content: ' ';*/
/*    position: absolute;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    background: red;*/
/*    left: 0;*/
/*    top: 0;*/
/*}*/
.initMic {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.75);
    left: 0;
    top: 0;
}
.animate .svg-box img {
    animation: bloop 1s linear forwards;
}
.sepAudio {
    margin: 25px 0;
    width: 100%;
    height: 1px;
    background: rgba(128, 128, 128, 0.09);
}
.errRec .circle {
    /*background: rgba(199, 1, 1, 0.51);*/
}
.circle {
    display: block;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background: #7a6fbe;
    transition: background .2s linear, all 5s ease;
    position: absolute;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInDeleay {
    0% {
        opacity: 0;
        transform: translateX(10px);
    }
    80% {
        opacity: 0;
        transform: translateX(10px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes wavess {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1.8);
        opacity: 0;
    }
}
@keyframes bloop {
    0% {
        transform: scale3d(1, 1, 1);
    }
    30% {
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        transform: scale3d(0.95, 1.05, 1);
    }
    75% {
        transform: scale3d(1.05, 0.95, 1);
    }
    75% {
        margin-top: 20px;
        transform: scale3d(.7, .7, 1);
    } 100% {
        margin-top: 30px;
        transform: scale3d(.7, .7, 1);
    }
}