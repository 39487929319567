.specialTitleInAudio {
    font-weight: bold;
    font-size: 24px;
}
.audio-short {
    padding: 30px;
    width: 100%;
}
/*.codeWrapMenu .svgContainer,*/
/*.codeWrapMenu .audio-short .textwraps {*/
/*    text-align: left;*/
/*}*/
.codeWrapMenu .audio-short {
    padding: 2px;
}
.countDownBeforeStart {
    width: calc(100% + 20px);
    height: calc(100% + 60px);
    top: 0;
    left: 0;
    margin-top: -30px;
    padding: 40px 0;
    box-sizing: border-box;
    margin-left: -10px;
    background: white;
    font-weight: bold;
    position: absolute;
    opacity: .8!important;
    z-index: 100;
    transition: all .4s ease;
}
.rel {
    position: relative;
}
.recognizing {
    /*display: none;*/
}
.recognizing {
    margin-top: 20px;
}
[data-recording="0"][data-exam="1"] .recognizing {
    display: block;
}
[data-recognizing="1"] .svgContainer
/*, [data-recording="0"][data-exam="1"] .svgContainer {*/
{
    opacity: .5;
    cursor: default;
    /*background: red!important;*/
}
.audio-short [disabled], .audio-short .o3 {
    opacity: .4!important;
}
.animate .rewriteMore {
    display: none;
}
.rewriteMore img {
    margin-top: -20px;
    left: -100px;
    margin-left: -80px;
    width: 70%;
    opacity: .5;


}
.rewriteMore span {
    animation: fadeInDeleay 1.1s linear forwards;
    font-size: 12px;
    width: 200px;
    text-align: left;
    margin-top: 6px;
    display: block;

}
.rewriteMore {
    width: 60px;
    text-align: center;
    height: 100px;
    position: absolute;
    right: -100px;
    top: -20px;
    opacity: .8;
    animation: fadeInDeleay 1s linear forwards;
}
.audioTitle {
    font-size: 24px;
    font-wight: bold;
    padding: 10px;
}
.shortTag.selected {
    background: rgba(122, 111, 190, 0.24);
    box-shadow: inset 2px 2px 2px rgba(122, 111, 190, 0.57);
}
.shortTag {
    display: inline-block;
    border: 1px solid grey;
    border-radius: 10px;
    margin: 3px;
    padding: 2px 10px;
    font-size: 12px;
    cursor: pointer;
}
.tc {
    text-align: center;
}

.svg-box img {
    margin-top: 19px;
    transition: all .4s ease;
}
.svg-box {
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 50%;
    vertical-align: middle;
    justify-content: center;
    display: flex;
}
/*.micWrap {*/
/*    border: 1px solid grey;*/
/*    border-radius: 50%;*/
/*    width: 70px;*/
/*    height: 70px;*/
/*    padding: 10px;*/
/*    position: relative;*/
/*    cursor: pointer;*/
/*}*/
/*.micWrap::after {*/
/*    content: ' ';*/
/*    position: absolute;*/

/*    width: 300%;*/
/*    height: 300%;*/

/*    margin-left: -50%;*/
/*    margin-top: -50%;*/

/*    background: gradient(white, green);*/
/*    border-radius: 50%;*/
/*    opacity: .8;*/
/*}*/
.backgWrap {
    background: red;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    border-radius: 50%;
    
}