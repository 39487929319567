.login-body {
    width: 440px;
    max-width: 95%;
    padding-top: 50px;
    margin: 0 auto;
    color: white;
}
.login-form h1 {
    color: #000;
    margin-top: 20px;
    margin-bottom: 20px;
    zoom: 1.5;
}
.login-form {
    padding: 20px;
    background: white;
    border-radius: 15px;
    color: #74767d;
}

#checkboxPrivate {
    width: auto;
    padding: 10px;
    zoom: 1.1;
    margin-right: 5px;
    vertical-align: top;
    height: 15px;
    margin-top: 0px;
    accent-color: #7a6fbe;
}
.page-content {
    background: transparent!important;
}

input {
    border: 1px solid #8080801f!important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    /*-webkit-text-fill-color: #ffffff;*/
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}

.tlLogin {
    text-align: center;
}
.tlLogin span{
    text-align: center;
}