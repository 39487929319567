.progressBar::before{
    background: lightgrey;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
}
.progressBar {
    position: relative;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    overflow: hidden;
    max-width: 100px;
}
.progressIt {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    min-width: 10px;
    background: green;
    transition: all .4s ease;
    border-radius: 5px;
}
.progressBar.bad .progressIt {
    background: red
}
.progressBar.norm .progressIt {
    background: orange
}