.card-status {
    border: 1px solid lightgrey;
    padding: 20px;
    font-weight: bold;
    text-align: center;
    border-radius: 10px;
}

.programs-list > div {
    display: inline-block;
    padding: 5px 15px;
    border: 1px solid lightgrey;
    border-radius: 10px;
    margin-right: 15px
}
.questions-stats {
    margin-top: 15px;
}
.inputTags {
    padding: 5px;
    margin-top: -15px;
}